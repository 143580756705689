import React, { useEffect, useState } from 'react'


import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  NavLink,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { BsSpeedometer, BsFillPeopleFill } from 'react-icons/bs'
import { BiBlock, BiStore } from 'react-icons/bi'
import { VscWorkspaceTrusted } from 'react-icons/vsc'

import { Navigate, Navigation, useNavigate } from "react-router-dom";
import {
  AiFillAppstore,
  AiFillDatabase,
  AiOutlineRise,
  AiOutlineAppstoreAdd,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { GiKnightBanner } from 'react-icons/gi'
// import Contactlist from "../contact/Contactlist";
import logo from "../../assets/images/logo.png";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import './index.css'
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import baseURL from "../../Services/Url";
import { AgGridReact } from "ag-grid-react";
import logozelt from '../../assets/imagesCustomer/logozelt.png'
import { useLocation } from "react-router-dom";



function SideBar({ Navigation }) {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false)
  const [counter, setCounter] = useState(0)
  const [liveRate, setLiveRate] = useState("");


  useEffect(() => {
    liveRateData();
  }, [])

  const liveRateData = async () => {
    try {
      const liveData = await axios.get("https://zelt-admin-api.moshimoshi.cloud/api/user/live-rate",
        {
          headers: {
            "x-access-token": localStorage.getItem("accessToken"),
          }
        });
      if (liveData?.status === 200) {
        console.log("liveData?.data", liveData?.data);
        const twentyTwoKaratValue22 = parseInt(liveData?.data?.goldRatePerGram22K)?.toFixed(2);

        setLiveRate(twentyTwoKaratValue22)

      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (e) => {

    // console.log(e);
    Navigation.navigate(`/list_out`, {
      data: e
    })
  }

  const userType = '';

  const AdminScreens = [{
    path: '/dashboard',
    name: 'Dashboard',
    icon: <BsSpeedometer className="icons" />
  },
  {
    path: '/customer',
    name: 'Customer',
    icon: <BsFillPeopleFill className="icons" />

  },
  {
    path: '/vendor',
    name: 'Vendor',
    icon: <BiStore className="icons" />

  },
  // {
  //   path: '/addvendor',
  //   name: 'Add Vendor',
  //   icon: <AiOutlineAppstoreAdd className="icons" />

  // },
  // {
  //   path: '/vendorRequest',
  //   name: 'Vendor Request',
  //   icon: <AiOutlineAppstoreAdd className="icons" />


  // },
  {
    path: '/Request',
    name: 'All Request',
    icon: <GiKnightBanner className="icons" />

  },
  {
    path: '/BlockedList',
    name: 'Blocked List',
    icon: <BiBlock className="icons" />

  },
  {
    path: '/TermsConditions',
    name: 'Terms & Conditions',
    icon: <VscWorkspaceTrusted className="icons" />

  },
  ]

  // const VendorScreens = [{
  //   path: '/vendorDashboard',
  //   name: 'Vendor Dashboard'
  // },
  // {
  //   path: '/vendorProfile',
  //   name: 'Profile'
  // },
  // {
  //   path: '/vendorStore',
  //   name: 'My Store'
  // },
  // {
  //   path: '/vendorAdmin',
  //   name: 'Admin'
  // },
  // {
  //   path: '/vendorProducts',
  //   name: 'Physical Jewellery'
  // },
  // {
  //   path: '/vendorScheme',
  //   name: ' Scheme'
  // },
  // {
  //   path: '/vendorStats',
  //   name: 'Stats'
  // },
  // {
  //   path: '/vendorBrochure',
  //   name: 'Brochure'
  // },
  // ]

  return (
    <div>
      <div className="d-inline-flex px-2 pt-2 gap-2">
        <img src={logozelt} width="25%" height="20%" className="m-0" alt='logo' />
        <h1>Zelt</h1>
      </div>
      <div>
        {userType === '' && //userType=== 'Admin'
          <>
            {AdminScreens.map((nav, i) => {
              return (
                <p key={i} className={`cursor fs-6 pt-2 pb-1 px-2 m-0 ${location.pathname == nav.path ? 'active' : ''} `}>
                  <Link to={nav?.path} className="p-1">
                    {nav?.icon} {nav?.name}
                  </Link>
                </p>
              )
            })}
          </>
        }

      </div>
      <div className='d-flex justify-content-center mt-5 align-content-center' style={{ backgroundColor: '#d9d6a9' }}>
        <div className='mt-2'>
          <h1 className='fs-6 fw-bold w-100 m-1'>Live Rate</h1>
          {/* <p className='fw-bold text-danger w-100 '>{liveRate}</p> */}
          {/* <Row>
              <Col md={8}>24ct @1gm</Col>
              <Col md={4} className='text-danger'>27000</Col>
            </Row> */}
          <Row >
            <Col md={7}>22ct @1gm</Col>
            <Col md={3} className='text-danger'>{liveRate}/-</Col>
          </Row>
          {/* <Row>
              <Col md={8}>Silver @1gm</Col>
              <Col md={4} className='text-danger'>27000</Col>
            </Row>
            <Row>
              <Col md={8}>Platinum @1gm</Col>
              <Col md={4} className='text-danger'>27000</Col>
            </Row> */}
        </div>
      </div>
    </div>
  );
}
// }

export default SideBar;
