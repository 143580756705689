import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Form, Row, Col, Button, Modal } from 'react-bootstrap'; // Import Modal component

function AddVendor() {

    const [vendorData, setVendorData] = useState({
        name: '',
        email: '',
        address: '',
        phone: '',
        addline1: '',
        addline2: '',
        landMark: '',
        city: '',
        state: '',
        pincode: '',
        profilePic: null // To store the uploaded profile picture

    });

    const [vendorDataError, setVendorDataError] = useState({
        // Error properties for vendor data and society address
        name: '',
        email: '',
        address: '',
        phone: '',
        addline1: '',
        addline2: '',
        landMark: '',
        city: '',
        state: '',
        pincode: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVendorData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleAddVendor = () => {
        // Handle logic for adding a new vendor using vendorData state
        console.log('Adding vendor:', vendorData);
        // ... Perform API calls or other necessary actions ...
    };

    const handleProfilePicChange = (e) => {
        const file = e.target.files[0];
        setVendorData((prevData) => ({
            ...prevData,
            profilePic: file
        }));
    };
    return (
        <div>
            <div class="sidebar">
                <SideBar />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                </div>
                <div>
                    <Form>
                        <Form.Group controlId="formProfilePic">
                            <Form.Label>Profile Picture</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleProfilePicChange}
                            />
                        </Form.Group>
                        {vendorData.profilePic && (
                            <img
                                src={URL.createObjectURL(vendorData.profilePic)}
                                alt="Profile"
                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                            />
                        )}
                        <Form.Group controlId="formVendorName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter vendor name"
                                value={vendorData.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formVendorEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter vendor email"
                                value={vendorData.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form>
                            <Form.Group controlId="addline1">
                                <Form.Label>Address Line 1</Form.Label>
                                <Form.Control
                                    name="addline1"
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    value={vendorData.addline1}
                                    className={`placeholder-light form-control ${vendorDataError.addline1 ? 'border-danger' : ""}`}
                                    placeholder="Address Line 1"
                                />
                                <span className="text-danger">{vendorDataError.addline1}</span>
                            </Form.Group>
                            <Form.Group controlId="addline2">
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control
                                    name="addline2"
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    value={vendorData.addline2}
                                    className={`placeholder-light form-control ${vendorDataError.addline2 ? 'border-danger' : ""}`}
                                    placeholder="Address Line 2"
                                />
                                <span className="text-danger">{vendorDataError.addline2}</span>
                            </Form.Group>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Form.Group controlId="landMark">
                                    <Form.Label>Landmark / Locality</Form.Label>
                                    <Form.Control
                                        name="landMark"
                                        type="text"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        value={vendorData.landMark}
                                        className={`placeholder-light form-control ${vendorDataError.landMark ? 'border-danger' : ""}`}
                                        placeholder="Landmark / Locality"
                                    />
                                    <span className="text-danger">{vendorDataError.landMark}</span>
                                </Form.Group>
                                <Form.Group controlId="city">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        name="city"
                                        type="text"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        value={vendorData.city}
                                        className={`placeholder-light form-control ${vendorDataError.city ? 'border-danger' : ""}`}
                                        placeholder="City"
                                    />
                                    <span className="text-danger">{vendorDataError.city}</span>
                                </Form.Group>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>

                                <Form.Group controlId="state">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        name="state"
                                        type="text"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        value={vendorData.state}
                                        className={`placeholder-light form-control ${vendorDataError.state ? 'border-danger' : ""}`}
                                        placeholder="State"
                                    />
                                    <span className="text-danger">{vendorDataError.state}</span>
                                </Form.Group>
                                <Form.Group controlId="pincode">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control
                                        name="pincode"
                                        type="number"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        value={vendorData.pincode}
                                        className={`placeholder-light form-control ${vendorDataError.pincode ? 'border-danger' : ""}`}
                                        placeholder="Pincode"
                                    />
                                    <span className="text-danger">{vendorDataError.pincode}</span>
                                </Form.Group>
                            </div>


                        </Form>
                        <Form.Group controlId="formVendorPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter vendor phone"
                                value={vendorData.phone}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={handleAddVendor}>
                            Add Vendor
                        </Button>
                    </Form>


                </div>
            </div>
        </div>
    )
}

export default AddVendor