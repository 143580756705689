import React, { useState, useEffect } from 'react'
import SideBar from '../../../dashboard/SideBar'
import FirstNavbar from '../../../dashboard/FirstNavbar'
import { Card, Form, Row, Col, Button, Modal, Figure } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import AuthServices from '../../authServices/AuthServices';
import axios from 'axios';

const image = require('../../../../assets/imagesCustomer/image.png');

function AdvertisementBlocked() {

    const navigate = useNavigate();

    const [docmodal, setDocModal] = useState(false)
    const [adSelected, setAdSelected] = useState()

    const handleCloseModal = () => setDocModal(false)
    const handleShowModal = (data) => {
        console.log('dddddddaaaaaata', data);
        setAdSelected(data)
        setDocModal(true)
    }

    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        adBlockedList()
    }, [])

    let token = localStorage.getItem('accessToken');

    const adBlockedList = async () => {
        try {
            const data = await axios.get(
                `https://zelt-admin-api.moshimoshi.cloud/api/Advertisement/getBlockedAdvertisement`, {
                headers: {
                    "x-access-token": token
                }
            }
            );
            console.log("asfsdf", data.data.Advertisement);
            setRowData(data.data.Advertisement);
        } catch (error) {
            console.error(error);
        }
    };

    const approveAd = async (data) => {
        try {
            console.log("APPROVE BANNER--->", data)
            const AdvertisementPayload = {
                AdvertisementID: data?.data?._id,
                Status: "Approved",
            }
            const AdvertisementList = await axios.patch(
                `https://zelt-admin-api.moshimoshi.cloud/api/Advertisement/AccptorRejectAdvertisement`,
                AdvertisementPayload,
                {
                    headers: { "x-access-Token": localStorage.getItem("accessToken") },
                }
            );
            if (AdvertisementList.status === 200) {
                adBlockedList()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const declineAd = async (data) => {
        try {
            const bannerPayload = {
                advertismentId: data?._id,
                status: "blocked",
            }
            const bannerList = await AuthServices.patchAdmin('/user/approveMediaRequest', bannerPayload)
            console.log('bannerList', bannerList);
            if (bannerList.error === false) {
                // console.log('VendorList', vendorList);
                setRowData(bannerList?.data)
            }
        } catch (e) {
            console.log('error', e);
        }
    }


    const rowHeight = 50;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,

        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "Advertisement Name",
            // filter: true,
            field: "name",
            valueGetter: 'data?.Heading',
        },
        {
            headerName: "Shop Name",
            // filter: true,
            field: "shop",
            valueGetter: "data?.StoreID?.name",
        },

        {
            headerName: "Phone Number",
            // filter: true,
            field: "phone",
            valueGetter: "data?.StoreID?.phoneNumber",
        },
        // {
        //     headerName: "Date of Request",
        //     // filter: true,
        //     field: "date",
        // },
        {
            headerName: "View",
            // filter: true,
            // field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon onClick={() => handleShowModal(params?.data)} className="editIcon" icon={faEye}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        {
            headerName: "Un-Block",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => approveAd(params)} variant="outline-warning">Un-Block</Button>
                    </div>
                </center>
            ),
        },
        // {
        //     headerName: "Decline",
        //     // filter: true,
        //     // field: "approve",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <div>
        //                 <Button onClick={() => declineAd(params?.data)} variant="outline-danger">Decline</Button>
        //             </div>
        //         </center>
        //     ),
        // },
    ])



    return (
        <div>
            <h3 className='headertext'>Advertisement Blocked</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: "70vh", width: "100%" }}
            >
                <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                />
            </div>
            <Modal
                show={docmodal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>Advertisement Image:</h4>
                <Modal.Body>
                    <Row>
                        <Figure>
                            <Figure.Image
                                width='100%'
                                height={600}
                                // alt="171x180"
                                src={adSelected?.Image}
                            />
                        </Figure>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default AdvertisementBlocked