import React, { useState, useEffect } from 'react'
import SideBar from '../../../dashboard/SideBar'
import FirstNavbar from '../../../dashboard/FirstNavbar'
import { Card, Form, Row, Col, Button, Modal, Figure } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import AuthServices from '../../authServices/AuthServices';
import axios from 'axios';

const image = require('../../../../assets/imagesCustomer/image.png');

function BannerRequest() {

    const navigate = useNavigate();

    const [docmodal, setDocModal] = useState(false)
    const [selectedBan, setSelectedBan] = useState()
    const handleCloseModal = () => setDocModal(false)
    const handleShowModal = (data) => {
        console.log('dataaaaaaaa', data);
        setSelectedBan(data)
        setDocModal(true)
    }

    const rowHeight = 50;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,

        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },

        {
            headerName: "Shop Name",
            // filter: true,
            field: "VendorID.name",
        },
        {
            headerName: "Phone",
            // filter: true,
            field: "VendorID.phoneNumber",
        },
        // {
        //     headerName: "Date of Request",
        //     // filter: true,
        //     field: "date",
        // },
        {
            headerName: "View Banner",
            // filter: true,
            // field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <div onClick={() => handleShowModal(params?.data)} className="editIcon" icon={faEye}> View Banner </div>{" "}
                </center>
            ),
        },
        {
            headerName: "Acccept",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => approveBanner(params?.data)} variant="outline-warning">Acccept</Button>
                    </div>
                </center>
            ),
        },
        {
            headerName: "Decline",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => declineBanner(params?.data)} variant="outline-danger">Decline</Button>
                    </div>
                </center>
            ),
        },
    ])

    const [rowData, setRowData] = useState([
        // { name: "Hari", shop: "Sri Vinayaka Jewellers", mail: 'hari@gmail.com', date:'22/10/2021' },
        // { name: "Manu", shop: "Sri Vinayaka Jewellers", mail: 'manu@gmail.com', date:'22/10/2021' },
        // { name: "Anu", shop: "Sri Vinayaka Jewellers", mail: 'anu@gmail.com', date:'22/10/2021' }
    ]);

    useEffect(() => {
        bannerRequestList()
    }, [])

    // const bannerRequestList = async () => {
    //     try {
    //         const response = await AuthServices.getAdmin('/user/bannerRequests')
    //         let bannerList = []
    //         console.log('response', response);
    //         if (response.error === false) {
    //             // console.log('VendorList', vendorList);
    //             console.log("BANNER DATA---->", response?.data)
    //             for (let i = 0; i < response.data.length; i++) {
    //                 let obj = response.data[i];
    //                 for (let j = 0; j < obj.banner_img.length; j++) {
    //                     let newObj = {
    //                         "_id": obj._id,
    //                         "name": obj.name,
    //                         "phone": obj.phone,
    //                         "banner_img_id": obj.banner_img[j]._id,
    //                         "banner_img": obj.banner_img[j]
    //                     };
    //                     bannerList.push(newObj);
    //                 }
    //             }

    //             setRowData(bannerList)
    //         }
    //     } catch (e) {
    //         console.log('error', e);
    //     }
    // }

    const bannerRequestList = async () => {
        let token = localStorage.getItem('accessToken');
        try {
            const data = await axios.get(
                `https://zelt-admin-api.moshimoshi.cloud/api/Banner/getAllBanner`, {
                headers: {
                    "x-access-token": token
                }
            }
            );
            console.log("asfsdf", data.data.Banner);
            setRowData(data.data.Banner?.filter((item) => item?.Status === 'Pending' && item?.deleted === false));
        } catch (error) {
            console.error(error);
        }
    };

    const approveBanner = async (data) => {
        try {
            console.log("APPROVE BANNER--->", data)
            const bannerPayload = {
                BannerID: data?._id,
                Status: "Approved",
            }
            const bannerList = await axios.patch(
                `https://zelt-admin-api.moshimoshi.cloud/api/Banner/AccptorRejectBanner`,
                bannerPayload,
                {
                    headers: { "x-access-Token": localStorage.getItem("accessToken") },
                }
            );
            if (bannerList.status === 200) {
                bannerRequestList()

            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const declineBanner = async (data) => {
        try {
            const bannerPayload = {
                BannerID: data?._id,
                Status: "Blocked",
            }
            const bannerList = await axios.patch(
                `https://zelt-admin-api.moshimoshi.cloud/api/Banner/AccptorRejectBanner`,
                bannerPayload,
                {
                    headers: { "x-access-Token": localStorage.getItem("accessToken") },
                }
            );

            console.log('bannerList', bannerList);
            if (bannerList.status === 200) {
                bannerRequestList()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    return (
        <div>
            <h3 className='headertext'>Banner Requests</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: "70vh", width: "100%" }}
            >
                <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                />
            </div>
            <Modal
                show={docmodal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>Banner Image:</h4>
                <Modal.Body>
                    <Row>
                        <Figure>
                            <Figure.Image
                                width='100%'
                                height={600}
                                // alt="171x180"
                                src={selectedBan?.Image}
                            />
                        </Figure>
                    </Row>
                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={handleCloseModal} variant="secondary">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default BannerRequest