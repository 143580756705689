import React, { useEffect, useState } from 'react'
import { Card, Figure, Row, Col, Table } from 'react-bootstrap'
import AuthServices from '../authServices/AuthServices'
const earRing = require('../../../assets/imagesCustomer/earRing.png')
const image = require('../../../assets/imagesCustomer/ring1.png')

function CustomerTransactions({ custId }) {
  // console.log('custId custTransaction', custId, custTransaction);
  const [productsList, setProductsList] = useState([])
  const [schemeList, setSchemeList] = useState([])

  useEffect(() => {
    customerData1()
  }, [])

  const customerData1 = async () => {
    try {
      const customerdata = await AuthServices.getAdmin(`/user/getTransactions?id=${custId}&type=customer`)
      // console.log('customerdata', customerdata);
      if (customerdata?.error === false) {
        // console.log('customerdata1', customerdata?.data?.orders?.filter((x) => x?.type === "scheme"));
        // console.log('customerdata1 products', customerdata?.data?.orders?.filter((x) => x?.type === "product"));
        setSchemeList(customerdata?.data?.orders?.filter((x) => x?.type === "scheme"))
        setProductsList(customerdata?.data?.orders?.filter((x) => x?.type === "product"))
      }
    } catch (e) {
      console.log('error', e);
    }
  }

  // const schemeList =

  return (
    <div>
       <h3 className='headertext'>Customer Transactions History :</h3>
      <Card>
        <h4> Transaction History of Schemes Customer Invested:</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Scheme Name</th>
              <th>Shop Name</th>
              <th>Tenure</th>
              <th>Min Amount</th>
              <th>Discount</th>
              <th>Payment Status</th>
              {/* <th>Payment Date</th> */}
            </tr>
          </thead>
          <tbody>
            {schemeList?.map((scheme, i)=>{return(
            <tr>
              <td key={i}>1</td>
              <td>{scheme?.scheme?.name}</td>
              <td>{scheme?.shop?.name}</td>
              <td>({scheme?.scheme?.duration?.customerTime} + {scheme?.scheme?.duration?.vendorTime})</td>
              <td>{scheme?.scheme?.minAmt}</td>
              <td>{scheme?.scheme?.discount} %</td>
              <td>{scheme?.payment !== '' ? 'paid' : 'Due'}</td>
              {/* <td>22/12/2021</td> */}
            </tr>
            )})}
           
          </tbody>
        </Table>
        <hr />
        <h4> Transaction History of Jewellery Purchased by Customer :</h4>
        <Row>
          <Col md={3}>
            <h6>Total No. of Jewellery Purchased : </h6>
          </Col>
          <Col md={2}>
            <h6>{productsList?.length}</h6>
          </Col>
        </Row>
        <Table striped bordered hover>
          <thead>

            <tr>
              <th>#</th>
              <th>Jewellery Name</th>
              <th>Jewellery Image</th>
              <th>Shop Name</th>
              <th>Price</th>
              <th>weight</th>
              {/* <th>category</th> */}
              <th>metal</th>
              {/* <th>purity</th> */}
              <th>Payment Status</th>
              {/* <th>Payment Date</th> */}
            </tr>
          </thead>
          <tbody>
            {productsList?.map((prod, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{prod?.products[0]?.title}</td>
                  <td>
                    <Figure>
                      <Figure.Image
                        width={100}
                        height={80}
                        // alt="171x180"
                        src={prod?.products[0]?.image[0]}
                      />
                    </Figure>
                  </td>
                  <td>{prod?.shop?.name}</td>
                  <td>{prod?.products[0]?.price}</td>
                  <td>{prod?.products[0]?.weight} gms</td>
                  {/* <td>ring</td> */}
                  <td>{prod?.products[0]?.metal}</td>
                  {/* <td>{prod?.products[0]?.purity}</td> */}
                  <td>{prod?.products[0]?.payment !== '' ? 'paid' : 'Due'}</td>
                  {/* <td>22/12/2021</td> */}
                </tr>
              )
            })}

          </tbody>
        </Table>
      </Card>
    </div>
  )
}

export default CustomerTransactions