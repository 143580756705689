import React from 'react'
import { Card, Figure, Row, Col, Table } from 'react-bootstrap'
const earRing = require('../../../assets/imagesCustomer/earring1.png')
const image = require('../../../assets/imagesCustomer/ring1.png')

function ProductList({ vendor, selectedShop }) {

  const shopData = vendor?.shops?.find((x) => x?._id === selectedShop)
console.log('shopData', shopData);
  return (
    <div>
      <Card className='p-2'>
        <h3 className='headertext'>Jewellery List :</h3>
        {/* <Row>
          <Col md={3}>
            <h6>Total No. of Jewellery Purchased : </h6>
          </Col>
          <Col md={2}>
            <h6> 10</h6>
          </Col>
        </Row> */}
        <hr />
        <h4 className='headertext'>Jewellery/Product list :</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Jewellery Name</th>
              <th>Jewellery Image</th>
              {/* <th>Price</th> */}
              <th>weight</th>
              <th>metal</th>
              <th>purity</th>

            </tr>
          </thead>
          <tbody>
            {shopData?.products?.map((prod, i) => {
              return (
                <tr key={i}>
                  <td>{i+1}</td>
                  <td>{prod?.title}</td>
                  <td>
                    <Figure>
                      <Figure.Image
                        width={100}
                        height={80}
                        // alt="171x180"
                        src={prod?.image[0]}
                      />
                    </Figure>
                  </td>
                  {/* <td>6000</td> */}
                  <td>{prod?.weight} gms</td>
                  <td>{prod?.metal}</td>
                  <td>{prod?.purity}</td>
                </tr>
              )
            })}

          </tbody>
        </Table>
      </Card>
    </div>
  )
}

export default ProductList