import React, { useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form } from 'react-bootstrap'
import Plot from 'react-plotly.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

const image = require('../../../assets/imagesCustomer/image.png');


function TermsConditions() {

  return (
    <div>
      <div class="sidebar">
        <SideBar />
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Terms & Conditions:</h3>
          <div style={{height:1000}}>
            <Card style={{height:700}} className='p-2'>
              <iframe src="https://docs.google.com/document/d/1MdI_wKKvzhc2nXWI3h8lcC5UkPL51Rk5VrrlEivR1dA/edit?usp=sharing" style={{height:"100%"}} title="Iframe Example"></iframe>
            </Card>
            <Card className='p-2'>
              <Row>
                <Col md={2}>
                {/* style={{backgroundColor:'#d5af61', textAlign:'center', display:'inline-block',}} */}
                  <a href="https://docs.google.com/document/d/1MdI_wKKvzhc2nXWI3h8lcC5UkPL51Rk5VrrlEivR1dA/edit" style={{backgroundColor:'#d5af61', textAlign:'center', display:'inline-block', height:50, borderRadius:10}} target="_blank" title="Edit Terms & Conditions" >Edit Terms & Conditions</a>
                </Col>
              </Row>
              <hr />
            </Card>
          </div>
        </div>
      </div>
    </div >
  )
}

export default TermsConditions