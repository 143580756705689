import React, { useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Form, Row, Col } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import ProductRequest from './product/ProductRequest';
import BannerRequest from './banner/BannerRequest';
import AdvertisementRequest from './advertisement/AdvertisementRequest';
import Advertisements from './advertisement/Advertisements';
import NotificationRequest from './Notification/NotificationRequest';

// const earRing = require('../../../assets/imagesCustomer/earRing1.png')
// const image = require('../../../assets/imagesCustomer/image18.png')


function Request() {
    const [selection, setSelection] = useState('1')

    const onDataSelect = (e) => {
        setSelection(e.target.value)
    }

    return (
        <div>
            <div class="sidebar">
                <SideBar />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Request Data</h3>
                    <div>
                        <Card className="shadow rounded p-2 w-100">
                            <Row>
                                <Col md={3}>
                                    <div className="leftedge d-flex justify-content-space">
                                        <Form.Select
                                            aria-label="Default select example"
                                            size="sm"
                                            onChange={onDataSelect}
                                        >
                                            {/* <option>Please select Request:</option> */}
                                            <option value="1">Banner Request</option>
                                            <option value="2">Notification Request</option>
                                            <option value="3">Manage Advertisements</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            {selection === '1' && <BannerRequest />}
                            {/* {selection === '2' && <ProductRequest />} */}
                            {selection === '2' && <NotificationRequest />}

                            {selection === '3' && <Advertisements />}
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Request