import React, { useState, useEffect } from 'react'
import { Card, Figure, Row, Col, Table } from 'react-bootstrap'
import AuthServices from '../authServices/AuthServices'
const earRing = require('../../../assets/imagesCustomer/earRing.png')
const image = require('../../../assets/imagesCustomer/ring1.png')

function VendorTransactions({ selectedShop }) {
  console.log('selectedShoppppppppp', selectedShop);
  const [productsList, setProductsList] = useState([])
  const [schemeList, setSchemeList] = useState([])

  useEffect(() => {
    customerData1()
  }, [])

  const customerData1 = async () => {
    try {
      const customerdata = await AuthServices.getAdmin(`/user/getTransactions?id=${selectedShop}&type=vendor`)
      console.log('customerdata', customerdata);
      if (customerdata?.error === false) {
        console.log('customerdata1', customerdata?.data?.orders?.filter((x) => x?.type === "scheme"));
        console.log('customerdata1 products', customerdata?.data?.orders?.filter((x) => x?.type === "product"));
        setSchemeList(customerdata?.data?.orders?.filter((x) => x?.type === "scheme"))
        setProductsList(customerdata?.data?.orders?.filter((x) => x?.type === "product"))
      }
    } catch (e) {
      console.log('error', e);
    }
  }
  return (
    <div>
      <Card>
        <h3 className='headertext'>Vendor Transactions History :</h3>
        <h4> Transaction History of Schemes :</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Scheme Name</th>
              <th>Customer Name</th>
              <th>Customer Phone</th>
              <th>Customer Mail</th>
              <th>Tenure</th>
              <th>Amount Invested</th>
              <th>Discount</th>
              <th>Payment Status</th>
              {/* <th>Payment Date</th> */}
            </tr>
          </thead>
          <tbody>
            {schemeList?.map((scheme, i) => {
              return (
                <tr key={i}>
                  <td>{i+1}</td>
                  <td>{scheme?.scheme?.name}</td>
                  <td>{scheme?.user?.name}</td>
                  <td>{scheme?.user?.phone}</td>
                  <td>{scheme?.user?.email || '-'}</td>
                  <td>({scheme?.scheme?.duration?.customerTime} + {scheme?.scheme?.duration?.vendorTime})</td>
                  <td>{scheme?.scheme?.minAmt}</td>
                  <td>{scheme?.scheme?.discount} %</td>
                  <td>{scheme?.payment !== '' ? 'paid' : 'due'}</td>
                  {/* <td>22/12/2021</td> */}
                </tr>
              )
            })}

          </tbody>
        </Table>
        <hr />
        <h4> Transaction History of Jewellery :</h4>
        {/* <Row>
          <Col md={3}>
            <h6>Total No. of Jewellery Purchased : </h6>
          </Col>
          <Col md={2}>
            <h6> 10</h6>
          </Col>
        </Row> */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Jewellery Name</th>
              <th>Jewellery Image</th>
              <th>Customer Name</th>
              <th>Customer Phone</th>
              <th>Customer Mail</th>
              <th>Price</th>
              <th>weight</th>
              {/* <th>category</th> */}
              <th>metal</th>
              <th>purity</th>
              <th>Payment Status</th>
              {/* <th>Payment Date</th> */}
            </tr>
          </thead>
          <tbody>
            {productsList?.map((prod, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{prod?.products[0]?.title}</td>
                  <td>
                    <Figure>
                      <Figure.Image
                        width={100}
                        height={80}
                        // alt="171x180"
                        src={prod?.products[0]?.image[0]}
                      />
                    </Figure>
                  </td>
                  <td>{prod?.user?.name}</td>
                  <td>{prod?.user?.phone}</td>
                  <td>{prod?.user?.email || '-'}</td>
                  <td>{prod?.products[0]?.price}</td>
                  <td>{prod?.products[0]?.weight} gms</td>
                  {/* <td>ring</td> */}
                  <td>{prod?.products[0]?.metal}</td>
                  <td>24</td>
                  <td>{prod?.payment !== '' ? 'paid' : 'due'}</td>
                  {/* <td>22/12/2021</td> */}
                </tr>
              )
            })}

          </tbody>
        </Table>
      </Card>
    </div>
  )
}

export default VendorTransactions