import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col } from 'react-bootstrap'
import Plot from 'react-plotly.js'
import axios from 'axios'


function DashboardAdmin() {
  let url = "https://zelt-admin-api.moshimoshi.cloud/user/usercount"
  // let url = "http://localhost:3004/user/usercount"
  let token = localStorage.getItem('accessToken');

  const [customerCount, setCustomerCount] = useState([])
  const [vendorCount, setVendorCount] = useState([])

  useEffect(() => {
    vendorDataList()
    customerDataList()
  }, []);

  const vendorDataList = () => {
    let token = localStorage.getItem('accessToken');
    axios.get("https://zelt-admin-api.moshimoshi.cloud/api/vendor/Allvendors", {
      headers: {
        "x-access-token": token
      }
    }).then((response) => {
      console.log(response.data.vendor, "RESPONSE DATA")
      if (response.status === 200) {
        setVendorCount(response.data.vendor?.filter((item) => item?.blockstatus === false))
      }
    });
  }

  const customerDataList = () => {
    let token = localStorage.getItem('accessToken');
    axios.get("https://zelt-admin-api.moshimoshi.cloud/api/user/alluser", {
      headers: {
        "x-access-token": token
      }
    }).then((response) => {
      // console.log(response.data.users, "RESPONSE DATA")
      if (response.status === 200) {
        setCustomerCount(response.data.users?.filter((item) => item?.Blockstatus === false))
      }
    });
  }

  return (
    <div>
      <div class="sidebar">
        <SideBar />
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Dashboard</h3>
          <div>

            <Card>
              <Card.Body>
                {/* <Card.Title>Dashboard</Card.Title> */}
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={3}>
                        <Card.Title className='headertext'>Customer Data</Card.Title>
                        <Card.Text>
                          Total number of Customers
                        </Card.Text>
                        <div style={{ borderRadius: 50, height: 100, width: 100, border: '2px solid #BE783B', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                          <h1 style={{}}>
                            {customerCount?.length}
                          </h1>
                        </div>
                      </Col>
                      {/* <Col md={9}>
                        <Plot
                          data={[
                            {
                              x: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                              y: [19, 14, 22, 14, 16, 19, 15, 14, 10, 12, 12, 16],
                              type: 'bar',
                            }

                          ]}
                          layout={{
                            width: '80%', height: 440, title: 'Chart showing no. of customers registered in the year 2020', xaxis: {
                              tickangle: -45
                            },
                          }}
                        />
                      </Col> */}
                    </Row>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <Card.Title className='headertext'>Vendor Data</Card.Title>
                    <Row>
                      <Col md={3}>
                        <Card.Text>
                          Total number of Vendors
                        </Card.Text>
                        <div style={{ borderRadius: 50, height: 100, width: 100, border: '2px solid #BE783B', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                          <h1 style={{}}>
                            {vendorCount?.length}
                          </h1>
                        </div>
                      </Col>
                      {/* <Col md={9}>
                        <Plot
                          data={[
                            {
                              x: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                              y: [19, 14, 22, 14, 16, 19, 15, 14, 10, 12, 12, 16],
                              type: 'bar',
                            }

                          ]}
                          layout={{
                            width: '80%', height: 440, title: 'Chart showing no. of vendors registered in the year 2020', xaxis: {
                              tickangle: -45
                            },
                          }}
                        />
                      </Col> */}
                    </Row>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

          </div>
        </div>
      </div>


      {/* <div className="row my-2">
              <div className="col-md-2">
                <h3>
                  <b>Dashboard</b>
                </h3>
              </div>
              </div> */}
      {/* <h1></h1> */}
    </div>
  )
}

export default DashboardAdmin