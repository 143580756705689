import React, { useState, useEffect } from 'react'
import SideBar from '../../../dashboard/SideBar'
import FirstNavbar from '../../../dashboard/FirstNavbar'
import { Card, Form, Row, Col, Button, Modal, Figure, Table } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import AuthServices from '../../authServices/AuthServices';
import axios from 'axios';

const image = require('../../../../assets/imagesCustomer/image.png');

function VendorsBlockedList() {

    const navigate = useNavigate();

    const [docmodal, setDocModal] = useState(false)

    const handleCloseModal = () => setDocModal(false)
    const handleShowModal = () => setDocModal(true)

    const rowHeight = 50;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,

        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "Shop Name",
            // filter: true,
            field: "shop",
            valueGetter: 'data?.name',
        },
        {
            headerName: "Vendor Name",
            // filter: true,
            field: "name",
            valueGetter: 'data?.name',
            cellRendererFramework: (params) => (
                <center>
                    <div onClick={() => handleShowModal()} >{params?.data?.name}</div>{" "}
                </center>
            ),
        },
        {
            headerName: "Phone Number",
            // filter: true,
            field: "phone",
            valueGetter: 'data?.phoneNumber',
        },
        {
            headerName: "Date blocked",
            // filter: true,
            field: "date",
            valueGetter: "data?.dateBlocked",
        },
        // {
        //     headerName: "View",
        //     // filter: true,
        //     // field: "view",
        //     cellRendererFramework: (params) => (
        //         <center>
        //             <FontAwesomeIcon onClick={() => handleShowModal()} className="editIcon" icon={faEye}> </FontAwesomeIcon>{" "}
        //         </center>
        //     ),
        // },
        {
            headerName: "Un Block",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => acceptVendor(params.data)} variant="outline-warning">Un Block</Button>
                    </div>
                </center>
            ),
        },
    ])

    const [rowData, setRowData] = useState([
        // { name: "Hari", shop: "Sri Vinayaka Jewellers", mail: 'hari@gmail.com', date: '22/10/2021' },
        // { name: "Manu", shop: "Sri Vinayaka Jewellers", mail: 'manu@gmail.com', date: '22/10/2021' },
        // { name: "Anu", shop: "Sri Vinayaka Jewellers", mail: 'anu@gmail.com', date: '22/10/2021' }
    ]);

    useEffect(() => {
        vendorBlockedList()
    }, [])


    const vendorBlockedList = () => {
        let token = localStorage.getItem('accessToken');
        axios.get("https://zelt-admin-api.moshimoshi.cloud/api/vendor/Allvendors", {
            headers: {
                "x-access-token": token
            }
        }).then((response) => {
            console.log(response.data.vendor, "RESPONSE DATA")
            if (response.status === 200) {
                setRowData(response.data.vendor?.filter((item) => item?.blockstatus === true))
            }
        });
    }



    const acceptVendor = async (data) => {
        let token = localStorage.getItem('accessToken');
        try {
            axios.get("https://zelt-admin-api.moshimoshi.cloud/api/vendor/unblockvendor/" + data?._id, {
                headers: {
                    "x-access-token": token
                }
            }).then((response) => {
                console.log(response.data.vendor, "RESPONSE DATA")
                if (response.status === 200) {
                    vendorBlockedList()
                }
            });
        } catch (e) {
            console.log('error', e);
        }
    }

    return (
        <div>
            <h3 className='headertext'>Vendors Blocked</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: "70vh", width: "100%" }}
            >
                <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                />
            </div>
            <Modal
                show={docmodal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <h4 className='headertext text-center'>Vendor Details:</h4>
                <Modal.Body>
                    <Row>
                        <Col md={3}>
                            <Figure>
                                <Figure.Image
                                    width={150}
                                    height={150}
                                    src={image}
                                />
                            </Figure>
                        </Col>
                        <Col md={3}>
                            <p>Name:</p>
                            <p>Phone Number:</p>
                            <p>E-mail Id:</p>
                            <p>Account Number:</p>
                            <p>Bank Name:</p>
                            <p>Ifsc code:</p>
                            <p>Gst Number:</p>
                        </Col>
                        <Col md={3}>
                            <p>Manoj</p>
                            <p>8754678989</p>
                            <p>man@gmail.com</p>
                            <p>231234543456</p>
                            <p>Sbi</p>
                            <p>SBIN00293839</p>
                            <p>hdfhjg2635245</p>
                        </Col>
                    </Row>
                    <Row>List of shops:</Row>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Store Name</th>
                                <th>Store Location</th>
                                <th>Store Number</th>
                                <th>Store E-mail id</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Sri Vinayaka Jewellers</td>
                                <td>Bangalore - jpnagar</td>
                                <td>328947485</td>
                                <td>svj@gmail.com</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Sri Vinayaka Jewellers</td>
                                <td>Bangalore - jpnagar</td>
                                <td>328947485</td>
                                <td>svj@gmail.com</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Sri Vinayaka Jewellers</td>
                                <td>Bangalore - jpnagar</td>
                                <td>328947485</td>
                                <td>svj@gmail.com</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default VendorsBlockedList