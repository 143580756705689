import React, { useState } from 'react'
import { Card, Figure, Row, Col, Table } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";

const earRing = require('../../../assets/imagesCustomer/earRing.png')
const image = require('../../../assets/imagesCustomer/ring1.png')

function ShopDashboard() {

  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    // filter: true,
    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      // filter: true,
      lockPosition: true,
    },
    {
      headerName: "Jewellery Name",
      // filter: true,
      field: "name",
    },
    // {
    //   headerName: "Jewellery Image",
    //   // filter: true,
    //   field: "image",
    //   {
    //   <center>
    //       <img
    //         style={{ width: "50px", height: "50px" }}
    //         src={params.data.image}
    //       />
    //     </center>
    //   }
    // },
    {
      headerName: "Price",
      // filter: true,
      field: "price",
    },
    {
      headerName: "Weight",
      // filter: true,
      field: "weight",
    },
    {
      headerName: "Category",
      // filter: true,
      field: "category",
    },
    {
      headerName: "Metal",
      // filter: true,
      field: "metal",
    },
    {
      headerName: "Purity",
      // filter: true,
      field: "purity",
    },
    {
      headerName: "purchased Date",
      filter: true,
      floatingFilter: true,
      field: "date",
    },
  ])

  const [rowData] = useState([
    { name: "ring", price: '3000 /-', weight: '1 gms', category: 'ring', metal: 'gold', purity: '22', date: '22/10/2022' },
    { name: "Ear Ring", price: '3000 /-', weight: '1 gms', category: 'ring', metal: 'gold', purity: '22', date: '22/10/2022' },
    { name: "Chain", price: '3000 /-', weight: '1 gms', category: 'ring', metal: 'gold', purity: '22', date: '22/10/2022' }
  ]);

  const rowHeight1 = 50;
  const DefaultColumnSetting1 = {
    sortable: true,
      // filter: true,
      floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs1, setColDefs1] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      // filter: true,
      lockPosition: true,
    },
    {
      headerName: "Scheme  Name",
      // filter: true,
      field: "name",
    },
    {
      headerName: "Tenure",
      // filter: true,
      field: "tenure",
    },
    {
      headerName: "Min Amount Amount Invested",
      // filter: true,
      field: "min",
    },
    {
      headerName: "Amount Invested",
      // filter: true,
      field: "invest",
    },
    {
      headerName: "Purchased Date",
      filter: true,
      floatingFilter: true,
      field: "date",
    },
  ])

  const [rowData1] = useState([
    { name: "Hari", tenure: " 6+1 ", min: '2000 /-', invest: '2000 /-', date :'10/10/2020' },
    { name: "Manu", tenure: "9+1", min: '1500 /-', invest: '2000 /-', date :'10/10/2020' },
    { name: "Anu", tenure: "10+1", min: '3000/-', invest: '3000 /-', date :'10/10/2020' }
  ]);

  return (
    <div>
      <Card className="p-2">
        <h3 className='headertext'>ShopDashboard Profile Details:</h3>
        <Row>
          <h6>Sri Vinayaka Jewellers</h6>
          <h6>#20, 3rd Cross, JP Nagar, bengalore - 560091</h6>
          <h6>svj.jpnagar@gmail.com</h6>
          <h6>9589458532</h6>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <Card>
              <Card.Body>
                <Card.Title>Total Schemes Sold</Card.Title>
                {/* <Card.Text>
                  Total number of Customers
                </Card.Text> */}
                <div style={{ borderRadius: 50, height: 100, width: 100, border: '2px solid #BE783B', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <h1 style={{}}>
                    20
                  </h1>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <Card.Title>Total Jewellery Sold</Card.Title>
                {/* <Card.Text>
                  Total number of Customers
                </Card.Text> */}
                <div style={{ borderRadius: 50, height: 100, width: 100, border: '2px solid #BE783B', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <h1 style={{}}>
                    20
                  </h1>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <hr />
        <h3 className='headertext'>Details of Jewellery Sold</h3>
        <div
          className="ag-theme-alpine"
          style={{ height: "70vh", width: "100%" }}>
          <AgGridReact
            rowHeight={rowHeight}
            columnDefs={colDefs}
            defaultColDef={DefaultColumnSetting}
            pagination={true}
            paginationPageSize={10}
            // onGridReady={onGridReady}
            rowData={rowData}
          />
        </div>
       
        <hr />
        <h3 className='headertext'>Details of Schemes Sold</h3>
        <div
          className="ag-theme-alpine"
          style={{ height: "70vh", width: "100%" }}>
          <AgGridReact
            rowHeight={rowHeight1}
            // columnDefs={columns}
            columnDefs={colDefs1}
            defaultColDef={DefaultColumnSetting1}
            pagination={true}
            paginationPageSize={10}
            // onGridReady={onGridReady}
            rowData={rowData1}
          />
        </div>
        {/* <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Scheme Name</th>
              <th>Shop Name</th>
              <th>Tenure</th>
              <th>Min Amount</th>
              <th>Amount Invested</th>
              <th>purchased Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>swarna</td>
              <td>sri vinayaka jewellers</td>
              <td>(6+1)</td>
              <td>1000</td>
              <td>2000</td>
              <td>23/11/2020</td>
            </tr>
            <tr>
              <td>2</td>
              <td>swarna</td>
              <td>sri vinayaka jewellers</td>
              <td>(6+1)</td>
              <td>1000</td>
              <td>2000</td>
              <td>23/11/2020</td>
            </tr>
            <tr>
              <td>3</td>
              <td>swarna</td>
              <td>sri vinayaka jewellers</td>
              <td>(6+1)</td>
              <td>1000</td>
              <td>2000</td>
              <td>23/11/2020</td>
            </tr>
          </tbody>
        </Table> */}
      </Card>
    </div>
  )
}

export default ShopDashboard