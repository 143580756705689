
import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Form, Row, Col } from 'react-bootstrap'
import VendorProfile from './VendorProfile'
import VendorSales from './VendorSales'
import VendorShops from './VendorShops'
import Brochure from './Brochure'
import ProductList from './ProductList'
import ShopDashboard from './ShopDashboard'
import SchemeList from './SchemeList'
import VendorTransactions from './VendorTransactions'
import { useLocation } from "react-router-dom";
import { async } from 'q'
import AuthServices from '../authServices/AuthServices'


function VendorData() {
    const { state } = useLocation();

    const [selection, setSelection] = useState(1)
    const [selectedShop, setSelectedShop] = useState()
    const [vendor, setVendor] = useState()
    const onDataSelect = (e) => {
        setSelection(e.target.value)
    }

    useEffect(() => {
        getVendorData()
    }, [])
    const getVendorData = async () => {
        try {
            const vendorData = await AuthServices.getAdmin(`/user/?id=${state?._id}`)
            console.log('vvvvvvvvvendorData', vendorData);
            if (vendorData?.error === false) {
                setVendor(vendorData?.data)
            }
        } catch (e) {
            console.log('error ===>', e);
        }
    }

    return (
        <div>
            <div class="sidebar">
                <SideBar />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Vendor Data</h3>
                    <div>
                        <Card className="shadow rounded p-2 w-100">
                            <Row>
                                <Col md={3}>
                                    <div className="leftedge d-flex justify-content-space">
                                        <Form.Select
                                            aria-label="Default select example"
                                            size={"sm"}
                                            className="selectsizesmall"
                                            onChange={(e) => setSelectedShop(e.target.value)}
                                        >
                                            {/* <option >Select Shop</option> */}
                                            {vendor?.shops?.map((shop, i) => {
                                                return (
                                                    <option key={i} value={shop?._id}>{shop?.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={3}>
                                    <div className="leftedge d-flex justify-content-space">
                                        <Form.Select
                                            aria-label="Default select example"
                                            size="sm"
                                            //   className="selectsizesmall"
                                            onChange={onDataSelect}
                                        >
                                            {/* <option>Please select Detail</option> */}
                                            <option value="1">VendorProfile</option>
                                            {/* <option value="2">Shop Dashboard</option> */}
                                            <option value="3">Jewellery/Product List</option>
                                            <option value="4">Schemes List</option>
                                            <option value="5">VendorTransactions </option>
                                            {/* <option value="6">Vendor Sales/Stats </option> */}
                                        </Form.Select>

                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            {selection == 1 && <VendorProfile vendor={vendor} />}
                            {/* {selection == 2 && <ShopDashboard />} */}
                            {selection == 3 && <ProductList vendor={vendor} selectedShop={selectedShop} />}
                            {selection == 4 && <SchemeList vendor={vendor} selectedShop={selectedShop}/>}
                            {selection == 5 && <VendorTransactions selectedShop={selectedShop} />}
                            {/* {selection == 6 && <VendorSales/>} */}

                        </Card>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorData