import React, { useState, useEffect } from 'react'
import SideBar from '../../../dashboard/SideBar'
import FirstNavbar from '../../../dashboard/FirstNavbar'
import { Card, Form, Row, Col, Button, Modal, Figure } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import AuthServices from '../../authServices/AuthServices';
import axios from 'axios';

const image = require('../../../../assets/imagesCustomer/image.png');

function NotificationRequest() {

    const navigate = useNavigate();


    const rowHeight = 50;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,

        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },

        {
            headerName: "Shop Name",
            // filter: true,
            field: "VendorID.name",
        },

        {
            headerName: "Store Name",
            // filter: true,
            field: "StoreID.name",
        },
        {
            headerName: "Phone",
            // filter: true,
            field: "VendorID.phoneNumber",
        },
        // {
        //     headerName: "Date of Request",
        //     // filter: true,
        //     field: "date",
        // },
        {
            headerName: "Notification",
            // filter: true,
            // field: "view",
            field: "Text",
        },
        {
            headerName: "Acccept",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => approveNotification(params?.data)} variant="outline-warning">Acccept</Button>
                    </div>
                </center>
            ),
        },
        {
            headerName: "Decline",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => declineNotification(params?.data)} variant="outline-danger">Decline</Button>
                    </div>
                </center>
            ),
        },
    ])

    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        NotificationRequestList()
    }, [])


    const NotificationRequestList = async () => {
        let token = localStorage.getItem('accessToken');
        try {
            const data = await axios.get(
                `https://zelt-admin-api.moshimoshi.cloud/api/Vendor/getAllNotification`, {
                headers: {
                    "x-access-token": token
                }
            }
            );
            console.log("asfsdf", data.data.Notification);
            setRowData(data.data.Notification?.filter((item) => item?.Status === 'Pending' && item?.deleted === false));
        } catch (error) {
            console.error(error);
        }
    };

    const approveNotification = async (data) => {
        try {
            console.log("APPROVE Notification--->", data)
            const NotificationPayload = {
                NotificationID: data?._id,
                Status: "Approved",
            }
            const NotificationList = await axios.patch(
                `https://zelt-admin-api.moshimoshi.cloud/api/Vendor/AccptorRejectNotification`,
                NotificationPayload,
                {
                    headers: { "x-access-Token": localStorage.getItem("accessToken") },
                }
            );
            if (NotificationList.status === 200) {
                NotificationRequestList()

            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const declineNotification = async (data) => {
        try {
            const NotificationPayload = {
                NotificationID: data?._id,
                Status: "Blocked",
            }
            const NotificationList = await axios.patch(
                `https://zelt-admin-api.moshimoshi.cloud/api/Vendor/AccptorRejectNotification`,
                NotificationPayload,
                {
                    headers: { "x-access-Token": localStorage.getItem("accessToken") },
                }
            );

            console.log('NotificationList', NotificationList);
            if (NotificationList.status === 200) {
                NotificationRequestList()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    return (
        <div>
            <h3 className='headertext'>Notification Requests</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: "70vh", width: "100%" }}
            >
                <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                />
            </div>

        </div>

    )
}

export default NotificationRequest