import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import AuthServices from '../authServices/AuthServices';

function VendorRequest() {

    const navigate = useNavigate();

    useEffect(() => {
        // vendorRequestList()
        vendorRequestList1()
        // acceptVendor()
    }, [])

    const [rowData, setRowData] = useState([]);

    // const vendorRequestList = async () => {
    //     try {
    //         const vendorList = await AuthServices.getAdmin('/user/all?type=vendor&account=requested')
    //         console.log('VendorList', vendorList);
    //         if (vendorList.error === false) {
    //             console.log('VendorList', vendorList);
    //             setRowData(vendorList?.data)
    //         }
    //     } catch (e) {
    //         console.log('error', e);
    //     }
    // }

    const vendorRequestList1 = async () => {
        try {
            const vendorList = await AuthServices.getAdmin('/user/approveList')
            console.log('VendorList', vendorList);
            if (vendorList?.error === false) {
                console.log('VendorListlength', vendorList);
                console.log('VendorListlengthdata', vendorList?.data);
                setRowData(vendorList?.data)
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const acceptVendor = async (data) => {
        try {
            console.log('ddddddddddata', data);
            console.log('ddddddddddataaaaaaa', data?._id);

            const payload = {
                _id: data?._id,
                status: 'approved',
            }
            const approveVendor = await AuthServices.patchAdmin('/user/approveOrBlockVendor', payload)
            console.log('approveVendor', approveVendor);
            if (approveVendor.error === false) {
                vendorRequestList1()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const declineVendor = async (data) => {
        try {
            const payload = {
                _id: data?._id,
                status: 'blocked',
            }
            const disApproveVendor = await AuthServices.patchAdmin('/user/approveOrBlockVendor', payload)
            console.log('disApproveVendor', disApproveVendor);
            if(disApproveVendor?.error === false){
                vendorRequestList1()
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const rowHeight = 50;
    const DefaultColumnSetting = {
        sortable: true,
        //   filter: true,
        //   floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    const [colDefs, setColDefs] = useState([
        // console.log('data', data)
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            // filter: true,
            lockPosition: true,
        },
        {
            headerName: "Shop Name",
            // filter: true,
            field: "shop",
            valueGetter: 'data?.shops[0]?.name',
        },
        {
            headerName: "Vendor name",
            // filter: true,
            field: "name",
            valueGetter: 'data?.name',
        },
        {
            headerName: "Phone Number",
            // filter: true,
            field: "data?.phone",
            valueGetter: 'data?.phone',
        },
        // {
        //     headerName: "Date of Request",
        //     // filter: true,
        //     field: "date",
        // },
        {
            headerName: "View",
            // filter: true,
            // field: "view",
            cellRendererFramework: (params) => (
                <center>
                    <FontAwesomeIcon onClick={() => navigate('/VendorDetail', { state: params.data})} className="editIcon" icon={faEye}> </FontAwesomeIcon>{" "}
                </center>
            ),
        },
        {
            headerName: "Accept",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => acceptVendor(params.data)} variant="outline-warning">Accept</Button>
                    </div>
                </center>
            ),
        },
        {
            headerName: "Decline",
            // filter: true,
            // field: "approve",
            cellRendererFramework: (params) => (
                <center>
                    <div>
                        <Button onClick={() => declineVendor(params.data)} variant="outline-danger">Decline</Button>
                    </div>
                </center>
            ),
        },
    ])



    return (
        <div>
            <div class="sidebar">
                <SideBar />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Vendor Requests</h3>
                    <div
                        className="ag-theme-alpine"
                        style={{ height: "70vh", width: "100%" }}
                    >
                        <AgGridReact
                            rowHeight={rowHeight}
                            // columnDefs={columns}
                            columnDefs={colDefs}
                            defaultColDef={DefaultColumnSetting}
                            pagination={true}
                            paginationPageSize={10}
                            // onGridReady={onGridReady}
                            rowData={rowData}
                        // onRowClicked={(e) => navigate('/customerData?id='+e.data._id)}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default VendorRequest